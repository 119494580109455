<template>
	<div>
		<div class="content flex-space-between">
			<AsideNavMenu :list="navMenuList" :index="navIndex" title="服务中心" @navMenuLink="navMenuLink"></AsideNavMenu>
			<div class="right">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import {postServeList} from '@/service/api/index'
import AsideNavMenu from '@/components/aside/navmenu.vue'

export default {
	components: {AsideNavMenu},
	data() {
		return {
			navMenuList: [],
			navIndex: 0,
			navbarId: 0
		}
	},

	mounted() {
		window.addEventListener('beforeunload', this.beforeunloadFn())
		let params = this.$route.params.info
		if (params) {
			this.navbarId = params.id
			sessionStorage.setItem('navbarId', params.id)
			this.postServeList(params.id)
		}
	},
	destroyed() {
		window.removeEventListener('beforeunload', this.beforeunloadFn())
	},

	methods: {
		postServeList(id) {
			postServeList({id}).then((res) => {
				// console.log(res)
				this.navMenuList = Object.freeze(res.msg)
				this.navIndex = this.navMenuList.findIndex((item) => item.is_show == 1)
				// console.log(this.navIndex)
			})
		},

		navMenuLink(item) {
			if (item.type > 2) {
				window.open(item.url, '_blank')
			} else {
				var name = ''
				if (item.type == 1) {
					name = 'information'
				} else if (item.type == 2) {
					name = 'opinion'
				}
				this.$router.replace({
					name: name
				})
			}
		},
		beforeunloadFn() {
			if (!this.$route.params.info) {
				this.navbarId = sessionStorage.getItem('navbarId')
				this.postServeList(this.navbarId)
			}
		}
	}
}
</script>

<style lang="less" scoped>
.content {
	align-items: flex-start;
}

.right {
	width: 1050px;
	margin-left: 20px;
	background: #fff;
	border-radius: 3px;
}
</style>
